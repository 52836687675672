:root {
  --main-bg-color: #282c34;
  --main-txt-color: #2b2929;
  --font-size-seq: calc(10px + 0.5vmin);
  --font-size-lg: calc(10px + 2vmin);
  --font-size-md: calc(10px + 1vmin);
  --font-size-sm: calc(10px + 0.75vmin);
}

*, *:before, *:after {
  box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: var(--main-txt-color);
}

.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title{
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.main-content {
  background-color: var(--main-bg-color);
  min-height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-size-lg);
  color: white;
  margin-top: 45px;
}

.root {
padding: '2px 4px';
display: 'flex';
text-align: 'center';
}

.input {
margin-left: 1em;
flex: 1;
width: 85%
}

.iconButton {
padding: 10;
float: right;
}

.float_right{
    float:right;
}

.align_right{
    text-align: center;
}

.float_left{
    float:left;
}

.divider {
height: 28;
margin: 4;
}

ul{
    padding-left: 0px;
}

.results{
    margin-top: 1rem;
}

.result-item{
    padding: 0px !important;
}

.result-item a{
    list-style-type: none;
    color: var(--main-txt-color);
    font-style: italic;
    text-decoration: none;
    display: block;
    padding: 8px 16px;
}

/*.result-item:hover, .result-item:active, .result-item:focus, .result-item:visited{
    background: #e4e4e4;
}*/

/*.result-item a{
    width: 100%;
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 8px 20px;
    box-sizing: border-box;
}*/

.result-item .MuiSvgIcon-root{
    float: right;
}

.grid-container {
  display: grid;
  grid-column-gap: 1rem;
  background: #fdfdfd;
  margin-top:1rem;
  grid-template-columns: 3fr 2fr;
}

.grid-container * {
    color: var(--main-txt-color);
    word-break: break-word;
}

.grid-title, .grid-subtitle{
    padding: 1rem;
    font-size: var(--font-size-md)
}

.grid-title, .grid-subtitle{
    align-self: start;
}

.grid-subtitle{
    width: 100%;
    float: left;
}

.grid-container hr{
    width: 100%;
    float: left;
}

.grid-alleles{
    padding: 1rem;
}

.grid-content{
    padding: 1rem;
    float: left;
    width: 100%;
}

.allele-container{
    margin-top: 1rem;
}

.grid-alleles .seq{
    font-size: var(--font-size-seq);
}

.seq, .seq > * > textarea{
    max-width: 60ch;
    font-family: Courier New !important;
    word-break: break-all;
    text-transform: uppercase;
}

.grid-right-side{
    padding: 1rem;
}

.population-name{
    float: left;
}

.clclass{
    float: right;
    font-size: 14px;
    display: flex;
}

.downloadicon{
    margin: 5px;
    cursor: pointer;
}

.AS1, .AS2, .AS3{
    padding: 5px 10px;
    color: white;
}

.AS1{
    background: rgb(251, 119, 110)
}

.AS2{
    background: rgb(126, 177, 1)
}

.AS3{
    background: rgb(41, 171, 227)
}

.divider-bottom{
    margin-top: 5px !important;
}

.igbCard, .igblastinput{
    width: 100%;
}

.igbCard .MuiCardContent-root{
    padding-bottom: 0px;
}

.igblastinput{
    margin-bottom: 10px !important;
}

.seqSearch{
    margin-bottom: 10px;
}

.searchbar{
    display: flex;
    margin-bottom: 10px;
}

.popuplink{
    margin-left: 10px;
    font-size: var(--font-size-sm);
    text-decoration: underline;
}

.qseqid-cell{
    width: 16ch;
}

.qseqid{
    white-space: nowrap;
    max-width: 10ch;
    overflow: hidden;
    float: left;
}

.moredots{
    float: left;
}

.MuiAppBar-colorPrimary{
    background: #3e3e3e !important;
}

.homepaper{
    float: left;
    width: 100%;
    margin-top: 1rem;
}

.block{
    margin: 2rem;
    float: left;
    width: calc(100% - 4rem);
}

.block .MuiDivider-root {
    margin: 1rem 0;
}

.textlink{
    text-decoration: underline;
}

.nomargin{
    margin: 0 0 !important;
}

.center{
    text-align: center;
}

.thesis-text{
    text-align: justify;
    line-height: 2 !important;
    margin: 0 10% !important;
}

.MuiTableContainer-root.thesis-text{
    width: auto;
    max-width: 400px;
}

.Analytics-tables{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}
.Analytics-tables:first-child {
    grid-column: 1/2;
}

.Analytics-tables .MuiTableCell-root{
    padding: 8px;
}

.Analytics-tables .MuiTableCell-head{
    font-weight: bold;
}

.Analytics-tables .MuiTableCell-root:first-child{
    font-style: italic;
}

.Analytics-tables .MuiTableCell-root{
    border-bottom: 0px;
    border-top: 1px solid rgba(0,0,0,0.12);
}

.Analytics-tables .MuiTypography-h6{
    padding: 8px 8px 0 8px;
}

.Analytics-tables table {
    height: calc(100% - 40px - 0.35em);
}

.Analytics-title-icon{
    margin: 14px 22px 0.35em 8px;

}

.MSA-labels{
    min-width: 120px;
    padding-right: 5px;
}

.MSA-container div .MSA-row{
    font-size: 1rem;
    display: flex;
    justify-content: left;
}

.MSA-container .seq {
    color: #999;
}

.MSA-row .seq,.MSA-row .seq>*>textarea{
    max-width: none;
}

.MSA-container .seq span{
    color: #000;
}

.MarginTop{
    margin-top: 10px;
}

.seq span{
    background: #ccc;
}

@media only screen and (max-width: 768px) {
    .grid-container{
        grid-template-columns: none;
    }
}
